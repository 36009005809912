import React from "react";

import PilotLogo from "../../../assets/Images/Testimonials/logos/pilot.svg";
import CasterLogo from "../../../assets/Images/Testimonials/logos/caster.svg";
import MiniappLogo from "../../../assets/Images/Testimonials/logos/miniapp.svg";
import SparkLogo from "../../../assets/Images/Testimonials/logos/spark.svg";
import WanderyLogo from "../../../assets/Images/Testimonials/logos/wandery.svg";
import AttisLogo from "../../../assets/Images/Testimonials/logos/attis.svg";
// import NexfluetnLogo from "../../../assets/Images/Testimonials/logos/nexfluent.svg";
// import AtreanLogo from "../../../assets/Images/Testimonials/logos/atrean.svg";
// import DatazipLogo from "../../../assets/Images/Testimonials/logos/datazip.svg";

const InfiniteScroll = () => {
  return (
    <div className="infinite-scroll">
      <div className="scrolling-logos">
        {/* <img src={NexfluetnLogo} alt="Nexfluent" /> */}
        {/* <img src={AtreanLogo} alt="Atrea" /> */}
        {/* <img src={DatazipLogo} alt="Datazip" /> */}
        <img src={CasterLogo} alt="Caster" />
        <img src={PilotLogo} alt="Pilot" />
        <img src={MiniappLogo} alt="Miniapp" />
        <img src={SparkLogo} alt="Spark" />
        <img src={WanderyLogo} alt="Wandery" />
        <img src={AttisLogo} alt="Attis" />

        {/* <img src={NexfluetnLogo} alt="Nexfluent" /> */}
        {/* <img src={AtreanLogo} alt="Atrea" /> */}
        {/* <img src={DatazipLogo} alt="Datazip" /> */}
        <img src={CasterLogo} alt="Caster" />
        <img src={PilotLogo} alt="Pilot" />
        <img src={MiniappLogo} alt="Miniapp" />
        <img src={SparkLogo} alt="Spark" />
        <img src={WanderyLogo} alt="Wandery" />
        <img src={AttisLogo} alt="Attis" />

        {/* <img src={NexfluetnLogo} alt="Nexfluent" /> */}
        {/* <img src={AtreanLogo} alt="Atrea" /> */}
        {/* <img src={DatazipLogo} alt="Datazip" /> */}
        <img src={CasterLogo} alt="Caster" />
        <img src={PilotLogo} alt="Pilot" />
        <img src={MiniappLogo} alt="Miniapp" />
        <img src={SparkLogo} alt="Spark" />
        <img src={WanderyLogo} alt="Wandery" />
        <img src={AttisLogo} alt="Attis" />
      </div>
    </div>
  );
};

export default InfiniteScroll;
